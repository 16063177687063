import {
  ADD_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  PROJECT_ERROR,
  UPDATE_PROJECT,
} from 'src/actions/types'

const initialState = {
  projects: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        filters: payload.filters,
        loading: false,
      }
    case ADD_PROJECT:
      return {
        ...state,
        projects: { data: [payload, ...state.projects.data] },
        loading: false,
      }
    case UPDATE_PROJECT: {
      let index = state.projects.data.findIndex((project) => project.id === payload.id)
      state.projects.data[index] = { ...state.projects.data[index], ...payload }
      return {
        ...state,
        projects: { data: [...state.projects.data], ...state.projects },
        loading: false,
      }
    }
    case DELETE_PROJECT:
      return {
        ...state,
        projects: { data: state.projects.data.filter((project) => project.id !== payload) },
        loading: false,
      }
    case PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
